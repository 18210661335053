
import React from "react"
import "../styles/logo.css"
import Includes from "../components/Includes"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Contact from "../components/Contact"
import Navigation from "../components/Navigation"



export default function contact() {
  return (<>
  
            <Header/>
            <Navigation/>
            <br></br>
            <Contact/>
            <Includes/>
            <Footer/>

        
  </>)
}

